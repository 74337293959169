import React, { Component, FormEvent, FunctionComponent } from "react";
import { Form, Alert, Col, Container, Row, Card } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { RouteComponentProps } from "react-router-dom";
import { Color, Path, Storage } from "../../../env";
import { Button, variant } from "../../form/Button";
import { FaCheck, FaCheckCircle } from "react-icons/fa";

export enum Action {
    Register
}

export interface CredentialsState {
    action: Action
    termsAgreed: boolean;
    fields: Map<formAttribute, string>;
    error: string;
    inviteCode: string;
}

export interface CredentialsProps extends RouteComponentProps {
    auth: typeof Auth;
}

export enum formAttribute {
    username = "username",
    email = "email",
    password = "password",
}

export default class Credentials extends Component<
    CredentialsProps,
    CredentialsState
> {
    constructor(props: CredentialsProps) {
        super(props);        
        const queryParams = new URLSearchParams(this.props.history.location.search)
        const email = queryParams.get("email") || ""
        this.state = {
            action: Action.Register,
            termsAgreed: false,
            fields: new Map([
                [formAttribute.username, ""],
                [formAttribute.email, email],
                [formAttribute.password, ""],
            ]),
            error: "",
            inviteCode: "",
        };
    }

    handleChange = (name: formAttribute, value: string) => {
        const fields = this.state.fields;
        fields.set(name, value);
        this.setState({
            fields: fields,
        });
    };

    isValidField = (
        field: formAttribute,
        fields: Map<formAttribute, string>
    ): boolean => {
        return (
            typeof fields.get(field) !== "undefined" &&
            fields.get(field)!.trim() !== ""
        );
    };

    handleNavigate = (path: Path) => {
        this.props.history.push(path);
    };

    isValidUsername = (value: string): boolean => {
        const regexPattern = /^[\p{Letter}\p{Mark}\p{Symbol}\p{Number}\p{Punctuation}]+$/u;
        return regexPattern.test(value);
    }

    handleSubmit = () => {
        if (
            this.isValidField(formAttribute.username, this.state.fields) ===
            false
        ) {
            const error = "Please provide your username";
            return this.setState({
                error: error,
            });
        }

        const username: string = this.state.fields
            .get(formAttribute.username)!
            .trim();

        if (!this.isValidUsername(username)) {
            return this.setState({
                error: "Username is invalid. Username cannot contain spaces and must be alphanumeric."
            })
        }

        if (
            this.isValidField(formAttribute.password, this.state.fields) ===
            false
        ) {
            const error = "Please provide a password";
            return this.setState({
                error: error,
            });
        }

        if (
            this.isValidField(formAttribute.email, this.state.fields) === false
        ) {
            const error = "Please provide your email";
            return this.setState({
                error: error,
            });
        }

        const password: string = this.state.fields
            .get(formAttribute.password)!
            .trim();
        const email: string = this.state.fields
            .get(formAttribute.email)!
            .trim();

        this.props.auth
            .signUp({
                username,
                password,
                attributes: {
                    email: email,
                },
            })
            .then(() => {
                this.props.history.push("/register/member/confirmation");
            })
            .catch((err) =>
                this.setState({
                    error: err.message,
                })
            );
    };

    handleAgreementChange = () => {
        this.setState({
            termsAgreed: !this.state.termsAgreed,
        })
    }

    handleInviteCodeChange = (value: string) => {
        this.setState({
            inviteCode: value,
        })
    }

    render() {
        const credentialFormProps: CredentialsFormProps = {
            termsAgreed: this.state.termsAgreed,
            username: this.state.fields.get(formAttribute.username)!,
            email: this.state.fields.get(formAttribute.email)!,
            password: this.state.fields.get(formAttribute.password)!,
            error: this.state.error,
            onAgreementChange: this.handleAgreementChange,
            onChange: this.handleChange,
            onSubmit: this.handleSubmit,
            onNavigate: this.handleNavigate,
        };
        return <CredentialsForm {...credentialFormProps} />;
    }
}

export interface CredentialsFormProps {
    termsAgreed: boolean;
    username: string;
    email: string;
    password: string;
    error: string;
    onAgreementChange: () => void;
    onChange: (name: formAttribute, value: string) => void;
    onSubmit: () => void;
    onNavigate: (path: Path) => void;
}

export const CredentialsForm: FunctionComponent<CredentialsFormProps> = (
    props
) => (
    <Container fluid>
        <Row>
            <Col className="d-none d-md-block" md={5} lg={6} style={{ paddingTop: "2rem" }}>
                <h1 style={{ marginBottom: "2rem" }}>Lets get started</h1>
                <Row>
                    <Col md={2} lg={1}>
                        <FaCheckCircle color={Color.Primary} size={20} />
                    </Col>
                    <Col md={10} lg={11}>
                        <p style={{ fontWeight: 600, margin: 0 }}>Get started quickly</p>
                        <p style={{ fontSize: ".9rem" }}>No technical expertise required. Register your shop, add content, set preferences and you're ready to go.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={2} lg={1}>
                        <FaCheckCircle color={Color.Primary} size={20} />
                    </Col>
                    <Col md={10} lg={11}>
                        <p style={{ fontWeight: 600, margin: 0 }}>Support any local business model</p>
                        <p style={{ fontSize: ".9rem" }}>Actively support any local independent retailers with their requirements.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={2} lg={1}>
                        <FaCheckCircle color={Color.Primary} size={20} />
                    </Col>
                    <Col md={10} lg={11}>
                        <p style={{ fontWeight: 600, margin: 0 }}>Cash flow friendly</p>
                        <p style={{ fontSize: ".9rem" }}>Online payments go directly to your account. No need to wait for payouts. No upfront or monthly fees.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={2} lg={1}>
                        <FaCheckCircle color={Color.Primary} size={20} />
                    </Col>
                    <Col md={10} lg={11}>
                        <p style={{ fontWeight: 600, margin: 0 }}>No contracts</p>
                        <p style={{ fontSize: ".9rem" }}>If the solution doesn't work for you, no problem, cancel any time at no cost.</p>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} md={7} lg={6}>
                <Card>
                    <Card.Body>
                        <div style={{ textAlign: "center" }}>
                            <img src={`/logos/${process.env.REACT_APP_LOGO}`} width={100} height={100} style={{ display: "inline-block" }} />
                        </div>
                        <Form>
                            <Container>
                                <Row>
                                    <Col>
                                        <Alert variant={"danger"} show={props.error != ""}>
                                            {props.error}
                                        </Alert>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group style={{ marginTop: ".7rem" }} controlId="formBasicUsername">
                                            <Form.Label style={{ fontSize: ".9rem" }}>Username</Form.Label>
                                            <Form.Control
                                                id={"username"}
                                                type="text"
                                                name={"username"}
                                                onChange={(e) =>
                                                    props.onChange(formAttribute.username, e.target.value)
                                                }
                                            />
                                        </Form.Group>
                                        <Form.Group style={{ marginTop: ".7rem" }} controlId="formBasicEmail">
                                            <Form.Label style={{ fontSize: ".9rem" }}>Email address</Form.Label>
                                            <Form.Control
                                                id={"email"}
                                                type="email"
                                                name={"email"}
                                                value={props.email}
                                                onChange={(e) =>
                                                    props.onChange(formAttribute.email, e.target.value)
                                                }
                                            />
                                        </Form.Group>
                                        <Form.Group style={{ marginTop: ".7rem" }} controlId="formBasicPassword">
                                            <Form.Label style={{ fontSize: ".9rem" }}>Password</Form.Label>
                                            <Form.Control
                                                id={"password"}
                                                type="password"
                                                name={"password"}
                                                onChange={(e) =>
                                                    props.onChange(formAttribute.password, e.target.value)
                                                }
                                            />
                                        </Form.Group>
                                        <Form.Group style={{ paddingTop: "1.2rem", paddingLeft: "1.5rem" }} controlId="termsConfirmation">
                                            <Form.Check
                                                checked={props.termsAgreed}
                                                onClick={() => props.onAgreementChange()}
                                            />
                                            <Form.Label style={{ fontSize: ".9rem" }}>I am over 18 years old and agree to the <a href="https://stumbled.online/terms.html">Terms and Conditions</a> and the <a href="https://stumbled.online/privacy.html">Privacy Policy</a></Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row style={{ marginTop: "1rem" }}>
                                            <Col xs={12}>
                                                <Button id={"register-btn"} style={{ width: "100%" }} onClick={() => props.onSubmit()} variant={variant.Primary} name={"Submit"} disabled={!props.termsAgreed} />
                                            </Col>
                                            <Col xs={12} style={{ textAlign: "center", marginTop: ".8rem" }}>
                                                <span style={{ textAlign: "center", fontSize: ".9rem" }}>Have an account? <a
                                                    style={{
                                                        color: Color.Primary,
                                                        cursor: "pointer",
                                                        marginTop: ".5rem"
                                                    }}
                                                    onClick={() =>
                                                        props.onNavigate(
                                                            Path.Login
                                                        )
                                                    }
                                                >
                                                    Login
                                                </a></span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
);
