import { Auth, Storage as S3 } from "aws-amplify";
import Papa from "papaparse";
import { Component, FunctionComponent } from "react";
import {
    Col,
    Container,
    Row,
    Card,
    Modal,
    Dropdown,
    Badge,
    Alert,
    Spinner,
    Button as BButton,
    OverlayTrigger,
    Popover,
} from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import {
    IGetProducts,
    AddProduct as AddProductAPI,
    UpdateProduct as UpdateProductAPI,
    Product as ProductDto,
    GetProduct,
    AddCategory as AddCategoryAPI,
    Category,
    SyncProducts,
    DeleteProducts as DeleteProductsAPI,
    IGetCategories,
    IToggleProduct,
    GetArchivedProducts,
    UpdateProductLifecycle,
} from "../../client/core";
import { Color, Path, Storage } from "../../env";
import AddCategory from "./AddCategory";
import AddProduct from "./AddProduct";
import DeleteProducts from "./DeleteProducts";
import ImportFromCSV from "./ImportFromCSV";
import LabelMaker from "./LabelMaker";
import Product from "./Product";
import { Button, icon, variant } from "../form/Button";
import { TbLetterA, TbLetterB, TbLetterC, TbLetterD, TbLetterE, TbLetterF, TbLetterG, TbLetterH, TbLetterI, TbLetterJ, TbLetterK, TbLetterL, TbLetterM, TbLetterN, TbLetterO, TbLetterP, TbLetterQ, TbLetterR, TbLetterS, TbLetterT, TbLetterU, TbLetterV, TbLetterW, TbLetterX, TbLetterY, TbLetterZ } from "react-icons/tb";
import { IconBaseProps } from "react-icons";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { FaLink } from "react-icons/fa";
import ArchivedProducts from "./ArchivedProducts";

export interface ListProductsState {
    isLoading: boolean;
    currentProducts: Array<ProductDto>;
    products: Array<ProductDto>
    activity: Activity;
    currentCategory: string;
    activeProductID: string;
    categories: Category[];
    error: string;
}

export interface ListProductsProps extends RouteComponentProps {
    coreAPI: IGetProducts;
    getCategoriesAPI: IGetCategories;
    toggleProductAPI: IToggleProduct;
    auth: typeof Auth;
}

export enum Activity {
    Add,
    List,
    View,
    Delete,
    AddCategory,
    LabelMaker,
    ImportFromCSV,
    ViewArchivedProducts,
    SyncWithShop,
}

export default class ListProducts extends Component<
    ListProductsProps,
    ListProductsState
> {
    constructor(props: ListProductsProps) {
        super(props);
        this.state = {
            isLoading: true,
            currentProducts: [],
            products: [],
            categories: [],
            currentCategory: "All",
            activeProductID: "",
            error: "",
            activity: Activity.List,
        };
    }

    componentDidMount() {
        this.getProducts();
    }

    changeActivity = (activity: Activity) => {
        this.setState({
            activity: activity,
        });
    };

    handleProductSearch = (input: string): void => {
        const filteredProducts: ProductDto[] = Array.from(this.state.products.values()).
            flat().
            filter(p => p.name.toLowerCase().includes(input.toLowerCase()))

        this.setState({
            currentProducts: filteredProducts
        })
    }

    handleAddProduct = (product: ProductDto) => {
        const { currentProducts: products } = this.state;
        products.unshift(product);
        this.setState({
            currentProducts: products,
            activity: Activity.List,
        });
    };

    handleAddCategory = (category: Category) => {
        this.setState({
            activity: Activity.List,
        });
    }

    handleSelect = (productID: string) => {
        this.setState({
            activeProductID: productID,
            activity: Activity.View,
        });
    };

    handleProductRefresh = (products: ProductDto[]) => {
        this.setState({
            currentProducts: products,
            activity: Activity.List,
        })
    }

    handleMergeSyncProducts = (products: ProductDto[]) => {
        const newArr: ProductDto[] = [...this.state.currentProducts];
        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            if (newArr.find(item => product.id === item.id)) continue;
            newArr.push(product);
        }
        this.setState({
            currentProducts: newArr,
            activity: Activity.List,
            categories: this.extractCategories(newArr)
        })
    }

    extractCategories = (products: ProductDto[]): Category[] => {
        const categories = Array.from(new Set(products.map(product => product.category)))
        return categories.filter(category => category && category.trim() !== "").map(category => {
            const response: Category = {
                name: category!
            }
            return response
        }).sort((a, b) => (a.name > b.name) ? 1 : -1)
    }

    getProducts = () => {
        const shopID = localStorage.getItem(Storage.ShopID);
        if (shopID == null) {
            return this.setState({
                error: "Shop could not be found - please navigate back to the shop dashboard to resync",
            });
        }

        this.props.auth.currentSession().then((session) => {
            const token = session.getIdToken().getJwtToken();
            this.props.coreAPI
                .GetProducts({
                    identityToken: token,
                    shopID: shopID!,
                })
                .then((output) => {
                    if (output.statusCode === 403) {
                        localStorage.removeItem(Storage.ShopID);
                        return this.setState({
                            error: "You were forbidden to list shop products - please navigate back to the shop dashboard.",
                        });
                    }

                    this.setState({
                        products: output.products,
                        currentProducts: output.products,
                        categories: this.extractCategories(output.products),
                    });
                })
                .catch((err) =>
                    this.setState({
                        error: "We were unable to retrieve the products at this time. Please check you internet connection.",
                    })
                ).finally(() => {
                    this.setState({
                        isLoading: false,
                    })
                })
        }).catch(() => {
            return this.setState({
                isLoading: false,
                error: "You were forbidden to list shop products - please navigate back to the shop dashboard.",
            });
        })
    };

    handleProductImageUpdate = (productID: string, image: File) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const text = e!.target!.result;
            const { currentProducts: products } = this.state;
            const newProducts = products.map((product) => {
                if (product.id == productID) {
                    product.image = text?.toString();
                }
                return product;
            });
            this.setState({
                currentProducts: newProducts as unknown as ProductDto[],
            });
        };
        reader.readAsDataURL(image);
    };

    handleProductInfoUpdate = (product: ProductDto) => {
        const { currentProducts: products } = this.state;
        const newProducts = products.map((p) => {
            if (p.id === product.id) {
                p = product;
            }
            return p;
        });
        this.setState({
            currentProducts: newProducts as unknown as ProductDto[],
        });
    };

    handleCategoryChange = (category?: Category) => {
        if (!category) {
            return this.setState({
                currentCategory: "All"
            })
        }
        this.setState({
            currentCategory: category.name,
        })
    }

    handleToggleProduct = (productID: string, enable: boolean) => {
        const shopID = localStorage.getItem(Storage.ShopID);
        if (shopID == null) {
            return this.setState({
                error: "Shop could not be found. Please navigate back to the shop dashboard to resync",
            });
        }

        this.props.auth.currentSession().then(session => {
            this.props.toggleProductAPI.ToggleProduct({
                identityToken: session.getIdToken().getJwtToken(),
                productID: productID,
                enable: enable,
                shopID: shopID,
            }).then(response => {
                if (response.statusCode !== 200) {
                    return this.setState({
                        error: "Sorry, we could no enable to the product at this time. Please try again later."
                    })
                }

                const { currentProducts, products } = this.state
                const newCurrentProducts = currentProducts.map((p) => {
                    if (p.id === productID) {
                        p.disabled = !enable
                    }
                    return p;
                });
                const newProducts = products.map((p) => {
                    if (p.id === productID) {
                        p.disabled = !enable
                    }
                    return p;
                });
                this.setState({
                    currentProducts: newCurrentProducts,
                    products: newProducts,
                })
            }).catch(() => {
                this.setState({
                    error: "Sorry, we could no enable to the product at this time. Please try again later."
                })
            })
        }).catch(err => {
            localStorage.removeItem(Storage.ShopID)
            localStorage.removeItem(Storage.IsLoggedIn)
            this.props.auth.signOut()
            this.props.history.push(Path.Login)
        })
    }

    render() {
        const props: ListProductsGridProps = {
            auth: this.props.auth,
            products: (this.state.currentCategory !== "All") ? this.state.currentProducts.filter(p => p.category === this.state.currentCategory) : this.state.currentProducts,
            categories: this.state.categories,
            activity: this.state.activity,
            activeProductID: this.state.activeProductID,
            error: this.state.error,
            currentCategory: this.state.currentCategory,
            onSelect: this.handleSelect,
            onAdd: this.handleAddProduct,
            changeActivity: this.changeActivity,
            history: this.props.history,
            location: this.props.location,
            match: this.props.match,
            staticContext: this.props.staticContext,
            onAddCategory: this.handleAddCategory,
            onCategoryChange: this.handleCategoryChange,
            onProductImageUpdate: this.handleProductImageUpdate,
            onProductInfoUpdate: this.handleProductInfoUpdate,
            onProductsRefresh: this.handleProductRefresh,
            onSearch: this.handleProductSearch,
            onToggleProduct: this.handleToggleProduct,
            onProductShopSync: this.handleMergeSyncProducts,
        };
        return (this.state.isLoading) ? <div style={{ textAlign: "center" }}><Spinner variant="success" animation="border" /></div> : <ListProductsGrid {...props} />;
    }
}

export interface ListProductsGridProps extends RouteComponentProps {
    auth: typeof Auth;
    products: Array<ProductDto>;
    categories: Category[];
    activeProductID: string;
    activity: Activity;
    error: string;
    currentCategory: string;
    onAdd: (product: ProductDto) => void;
    onSelect: (productID: string) => void;
    changeActivity: (activity: Activity) => void;
    onAddCategory: (category: Category) => void;
    onCategoryChange: (category?: Category) => void;
    onProductImageUpdate: (productID: string, image: File) => void;
    onProductInfoUpdate: (product: ProductDto) => void;
    onProductsRefresh: (products: ProductDto[]) => void;
    onProductShopSync: (products: ProductDto[]) => void;
    onSearch: (value: string) => void;
    onToggleProduct: (productID: string, enable: boolean) => void;
}

export const ListProductsGrid: FunctionComponent<ListProductsGridProps> = (
    props
) => (
    <>
        <Modal
            size="lg"
            show={props.activity == Activity.Add}
            onHide={() => props.changeActivity(Activity.List)}
        >
            <Modal.Header>
                <Modal.Title>Add Product</Modal.Title>
                <div style={{ flex: 1, textAlign: "right" }}>
                    <Button icon={icon.Close} variant={variant.Secondary} onClick={() => props.changeActivity(Activity.List)} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <AddProduct
                    {...props}
                    onImageUpdate={props.onProductImageUpdate}
                    imageStore={S3}
                    coreAPI={new AddProductAPI()}
                    postSubmit={(product) => props.onAdd(product)}
                />
            </Modal.Body>
        </Modal>

        <Modal
            size="lg"
            show={props.activity == Activity.ViewArchivedProducts}
            onHide={() => props.changeActivity(Activity.List)}
        >
            <Modal.Header>
                <Modal.Title>Archived Products</Modal.Title>
                <div style={{ flex: 1, textAlign: "right" }}>
                    <Button icon={icon.Close} variant={variant.Secondary} onClick={() => props.changeActivity(Activity.List)} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <ArchivedProducts
                    {...props}
                    UpdateProductLifecycle={new UpdateProductLifecycle().UpdateProductLifecycle}
                    getArchivedProducts={new GetArchivedProducts().GetArchivedProducts}
                />
            </Modal.Body>
        </Modal>

        <Modal
            size="lg"
            show={props.activity == Activity.View}
            onHide={() => props.changeActivity(Activity.List)}
        >
            <Modal.Header>
                <Modal.Title>Product</Modal.Title>
                <div style={{ flex: 1, textAlign: "right" }}>
                    <Button icon={icon.Close} variant={variant.Secondary} onClick={() => props.changeActivity(Activity.List)} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <Product
                    imageStore={S3}
                    updateProduct={new UpdateProductAPI().UpdateProduct}
                    updateProductLifecycle={new UpdateProductLifecycle().UpdateProductLifecycle}
                    onImageUpdate={props.onProductImageUpdate}
                    productID={props.activeProductID}
                    getProduct={new GetProduct().GetProduct}
                    onInfoUpdate={props.onProductInfoUpdate}
                    onClose={() => {
                        props.onProductsRefresh(props.products.filter(product => product.id !== props.activeProductID))
                        props.changeActivity(Activity.List)
                    }}
                    {...props}
                />
            </Modal.Body>
        </Modal>

        <Modal
            show={props.activity == Activity.AddCategory}
            onHide={() => props.changeActivity(Activity.List)}
        >
            <Modal.Header>
                <Modal.Title>Add Category</Modal.Title>
                <div style={{ flex: 1, textAlign: "right" }}>
                    <Button icon={icon.Close} variant={variant.Secondary} onClick={() => props.changeActivity(Activity.List)} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <AddCategory {...props} coreAPI={new AddCategoryAPI()} imageStore={S3} postSubmit={(category) => props.onAddCategory(category)} />
            </Modal.Body>
        </Modal>

        <Modal
            size="lg"
            show={props.activity == Activity.LabelMaker}
            onHide={() => props.changeActivity(Activity.List)}
        >
            <Modal.Header>
                <Modal.Title>Label Maker</Modal.Title>
                <div style={{ flex: 1, textAlign: "right" }}>
                    <Button icon={icon.Close} variant={variant.Secondary} onClick={() => props.changeActivity(Activity.List)} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <LabelMaker products={props.products} />
            </Modal.Body>
        </Modal>

        <Modal
            size="lg"
            show={props.activity == Activity.ImportFromCSV}
            onHide={() => props.changeActivity(Activity.List)}
        >
            <Modal.Header>
                <Modal.Title>Import From CSV</Modal.Title>
                <div style={{ flex: 1, textAlign: "right" }}>
                    <Button icon={icon.Close} variant={variant.Secondary} onClick={() => props.changeActivity(Activity.List)} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <ImportFromCSV {...props} coreAPI={new SyncProducts()} csvParser={Papa} postSubmit={props.onProductsRefresh} />
            </Modal.Body>
        </Modal>

        <Modal
            show={props.activity == Activity.Delete}
            onHide={() => props.changeActivity(Activity.List)}
        >
            <Modal.Header>
                <Modal.Title>Delete Products</Modal.Title>
                <div style={{ flex: 1, textAlign: "right" }}>
                    <Button icon={icon.Close} variant={variant.Secondary} onClick={() => props.changeActivity(Activity.List)} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <DeleteProducts {...props} coreAPI={new DeleteProductsAPI()} auth={Auth} products={props.products} postSubmit={ids => props.onProductsRefresh(props.products.filter(product => !ids.includes(product.id!)))} />
            </Modal.Body>
        </Modal>

        <Row>
            <h1 style={{ marginBottom: "2rem" }}>Products</h1>
        </Row>
        <Row>
            {props.categories && <CategoryMenu currentCategory={(props.currentCategory === "All") ? undefined : {
                name: props.currentCategory
            }} categories={props.categories} onSelect={props.onCategoryChange} />}
        </Row>
        <Row>
            <Col xs={12} md={3} xxl={2} style={{ textAlign: "left", marginBottom: "1rem" }}>
                <Dropdown>
                    <Dropdown.Toggle style={{ background: Color.Primary, border: 0, borderRadius: 0, width: "100%" }} id="actions">
                        Actions
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => props.changeActivity(Activity.Add)}>Add Product</Dropdown.Item>
                        <Dropdown.Item onClick={() => props.changeActivity(Activity.Delete)}>Delete Products</Dropdown.Item>
                        <Dropdown.Item onClick={() => props.changeActivity(Activity.AddCategory)}>Add Category</Dropdown.Item>
                        <Dropdown.Item onClick={() => props.changeActivity(Activity.LabelMaker)}>Label Maker</Dropdown.Item>
                        <Dropdown.Item onClick={() => props.changeActivity(Activity.ImportFromCSV)}>Import Products From CSV</Dropdown.Item>
                        <Dropdown.Item onClick={() => props.changeActivity(Activity.ViewArchivedProducts)}>View Archived Products</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col xs={12} md={9} xxl={10} style={{ marginBottom: "1rem" }}>
                <ProductSearch onChange={props.onSearch} />
            </Col>
        </Row>

        <p style={{ textAlign: "center", marginTop: "1rem" }}>Products</p>
        <Alert variant={"danger"} show={props.error != ""}>
            {props.error}
        </Alert>
        <Row style={{ marginTop: "-1rem" }}>
            {props.products.map((product) => (
                <Col
                    style={{ marginTop: "1rem" }}
                    key={product.id!}
                    xs={12}
                    sm={6}
                    md={4}
                    xl={3}
                    xxl={2}
                >

                    <Card style={{ border: 0, height: "100%" }}>
                        <a
                            key={product.id!}
                            id={"product-" + product.id!}
                            onClick={() => props.onSelect(product.id!)}
                            style={{ cursor: "pointer" }}
                        >
                            {(product.synchronized && product.synchronized !== "") && <Badge style={{ backgroundColor: Color.Primary, position: "absolute" }} bg={Color.Primary}>Sync: {product.synchronized}</Badge>}
                            {product.image && (
                                <Card.Img
                                    style={{
                                        width: "100%",
                                        height: "200px",
                                        objectFit: "cover",
                                    }}
                                    src={product.image!}
                                />
                            )}
                            {!product.image && (
                                <Card.Img
                                    style={{
                                        width: "100%",
                                        height: "200px",
                                        objectFit: "cover",
                                    }}
                                    src={`${process.env.REACT_APP_DEFAULT_IMAGE}${product.name.charAt(0).toUpperCase()}`}
                                />
                            )}
                        </a>
                        <Card.Body style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                            <Card.Title>{product.name}</Card.Title>
                            <Card.Text style={{ marginTop: ".8rem" }}>£{Number(product.price).toFixed(2)} {(!product.uom || product.uom === "Unit") ? "" : "per " + (((product.volume && product.volume > 1) ? `${product.volume} ` : "") + product.uom)}</Card.Text>
                            <div>
                                <BootstrapSwitchButton
                                    checked={!product.disabled}
                                    onstyle={"success"}
                                    offstyle="secondary"
                                    width={75}
                                    onChange={(checked: boolean) => props.onToggleProduct(product.id!, checked)}
                                />
                                <OverlayTrigger trigger="click" placement="right" rootClose overlay={(
                                    <Popover id="popover-basic">
                                        <Popover.Body>
                                            Product link copied
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <BButton style={{ float: "right", backgroundColor: Color.Primary, border: 0 }} variant={"success"} onClick={() => navigator.clipboard.writeText(`https://shops.${process.env.REACT_APP_BASE_DOMAIN}/shop/${localStorage.getItem(Storage.ShopID)}/product/${product.id!}`)} ><FaLink /></BButton>
                                </OverlayTrigger>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    </>
);

export interface ProductSearchProps {
    onChange: (input: string) => void
}

export const ProductSearch: FunctionComponent<ProductSearchProps> = (
    props
) => (
    <div className="input-group" style={{ width: "100%", margin: "auto" }}>
        <div className="form-outline" style={{ width: "100%", display: "flex" }}>
            <div className="form-outline flex-grow-1" >
                <input style={{ borderRadius: 0 }} onChange={e => props.onChange(e.target.value)} type="search" id="product-search-bar" className="form-control" placeholder="Search for a product" />
            </div>
            <Button onClick={() => { }} variant={variant.Primary} icon={icon.Search} />
        </div>
    </div>
)

export interface CategoryMenuProps {
    currentCategory?: Category
    categories: Category[]
    onSelect: (category?: Category) => void;
}

export const CategoryMenu: FunctionComponent<CategoryMenuProps> = (
    props
) => (
    <div className="scrolling" style={{ overflowX: "auto", display: "flex", padding: ".5rem", alignItems: "left", textAlign: "center", marginTop: "1rem" }}>
        <a style={{ cursor: "pointer" }} onClick={() => props.onSelect()}>
            <div style={{ display: "inline-block" }}>
                <div style={{ textAlign: "center", marginBottom: ".5rem" }}>
                    <span style={{ background: Color.Primary, padding: "0.5rem", borderRadius: "5px" }}>{Letter("a")}</span>
                </div>
                <p style={{ color: "#000", fontSize: ".9rem", margin: "0 1rem 0 1rem", fontWeight: (!props.currentCategory) ? 700 : 300, borderBottom: (!props.currentCategory) ? `.3rem solid ${Color.Primary}` : "none" }}>{"All"}</p>
            </div>
        </a>
        {props.categories.map(category => (
            <a style={{ cursor: "pointer", marginRight: ".2rem" }} onClick={() => props.onSelect(category)}>
                <div style={{ display: "inline-block" }}>
                    <div style={{ textAlign: "center", marginBottom: ".5rem" }}>
                        <span style={{ background: Color.Primary, padding: "0.5rem", borderRadius: "5px" }}>{Letter(category.name)}</span>
                    </div>
                    <p style={{ color: "#000", fontSize: ".9rem", margin: "0 1rem 0 1rem", fontWeight: (props.currentCategory?.name === category.name) ? 700 : 300 }}>{category.name}</p>
                    {(props.currentCategory?.name === category.name) && (
                        <div style={{ backgroundColor: Color.Primary, width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <OverlayTrigger trigger="click" placement="right" rootClose overlay={(
                                <Popover id="popover-basic">
                                    <Popover.Body>
                                        Category link copied
                                    </Popover.Body>
                                </Popover>
                            )}>
                                <BButton style={{backgroundColor: Color.Primary, border: 0 }} variant={"success"} onClick={() => navigator.clipboard.writeText(`https://shops.${process.env.REACT_APP_BASE_DOMAIN}/shop/${localStorage.getItem(Storage.ShopID)}?category=${props.currentCategory?.name}`)} ><FaLink /></BButton>
                            </OverlayTrigger>
                        </div>
                    )}
                </div>
            </a>
        ))}
    </div >
)

const Letter = (input: string) => {
    const fragments = Array.from(input)
    if (fragments.length === 0) {
        return
    }
    const props: IconBaseProps = {
        size: 20,
        color: Color.White
    }
    const icons = new Map([
        ["a", <TbLetterA {...props} />],
        ["b", <TbLetterB {...props} />],
        ["c", <TbLetterC {...props} />],
        ["d", <TbLetterD {...props} />],
        ["e", <TbLetterE {...props} />],
        ["f", <TbLetterF {...props} />],
        ["g", <TbLetterG {...props} />],
        ["h", <TbLetterH {...props} />],
        ["i", <TbLetterI {...props} />],
        ["j", <TbLetterJ {...props} />],
        ["k", <TbLetterK {...props} />],
        ["l", <TbLetterL {...props} />],
        ["m", <TbLetterM {...props} />],
        ["n", <TbLetterN {...props} />],
        ["o", <TbLetterO {...props} />],
        ["p", <TbLetterP {...props} />],
        ["q", <TbLetterQ {...props} />],
        ["r", <TbLetterR {...props} />],
        ["s", <TbLetterS {...props} />],
        ["t", <TbLetterT {...props} />],
        ["u", <TbLetterU {...props} />],
        ["v", <TbLetterV {...props} />],
        ["w", <TbLetterW {...props} />],
        ["x", <TbLetterX {...props} />],
        ["y", <TbLetterY {...props} />],
        ["z", <TbLetterZ {...props} />],
    ])
    return icons.get(fragments[0].toLowerCase())
}
