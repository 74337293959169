import { Auth, Storage as S3 } from "aws-amplify";
import { Component, FunctionComponent } from "react";
import { Path, Storage } from "../../env";
import { Alert, Form } from "react-bootstrap";
import { Button, variant } from "../form/Button";
import { IUpdateShopAlias } from "../../client/core";
import { RouteComponentProps } from "react-router-dom";

export interface UpdateAliasState {
    alias: string;
    error: string;
}

export interface UpdateAliasProps extends RouteComponentProps {
    auth: typeof Auth;
    updateAliasAPI: IUpdateShopAlias
    postSubmit: (alias: string) => void;
}

export default class UpdateAlias extends Component<
    UpdateAliasProps,
    UpdateAliasState
> {
    constructor(props: UpdateAliasProps) {
        super(props);
        this.state = {
            alias: "",
            error: "",
        };
    }

    handleChange = (alias: string) => {
        this.setState({
            alias: alias,
        });
    };

    handleSubmit = () => {
        const shopID = localStorage.getItem(Storage.ShopID);
        if (shopID == null) {
            return this.setState({
                error: "Shop could not be found. Please navigate back to the shop dashboard to resync",
            });
        }

        if (this.state.alias.trim() === "") {
            const error = "The alias value must be present before submission.";
            return this.setState({
                error: error,
            });
        }

        var regex = new RegExp("^[a-zA-Z0-9-]+$");
        if (!regex.test(this.state.alias)) {
            const error = "This alias is not valid. Alias must be alphanumeric and contain no spaces. Instead of spaces you can use hyphens (-).";
            return this.setState({
                error: error,
            });
        }

        this.props.auth.currentSession().then(session => {
            this.props.updateAliasAPI.UpdateShopAlias({
                identityToken: session.getIdToken().getJwtToken(),
                alias: this.state.alias,
                shopID: shopID,
            }).then(response => {
                if (response.statusCode !== 201) {
                    return this.setState({
                        error: "Sorry, we could not update your shop alias at this time. Please try again later."
                    })
                }

                this.props.postSubmit(this.state.alias)
            }).catch(err => {
                return this.setState({
                    error: "Sorry, we could not update your shop alias at this time. Please try again later."
                })
            })
        }).catch(err => {
            localStorage.removeItem(Storage.ShopID)
            localStorage.removeItem(Storage.IsLoggedIn)
            this.props.auth.signOut()
            this.props.history.push(Path.Login)
        })

    }

    render() {
        const props: UpdateAliasFormProps = {
            alias: this.state.alias,
            error: this.state.error,
            onChange: this.handleChange,
            onSubmit: this.handleSubmit,
        };
        return <UpdateAliasForm {...props} />;
    }
}

export interface UpdateAliasFormProps {
    alias: string;
    error: string;
    onChange: (alias: string) => void;
    onSubmit: () => void;
}

export interface UpdateAliasFormState {
    ImageName: string;
}

export const UpdateAliasForm: FunctionComponent<UpdateAliasFormProps> = (props) => (
    <Form>
        <Alert variant={"danger"} show={props.error != ""}>
            {props.error}
        </Alert>
        <p>Shop alias is used to provide a readable URL for your shop e.g. https://shops.{process.env.REACT_APP_BASE_DOMAIN}/shop/your-alias</p>
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">Alias</div>
            </div>
            <input
                type="text"
                className="form-control"
                name={"alias"}
                id={"alias"}
                placeholder="Enter alias"
                value={props.alias}
                onChange={(e) => props.onChange(e.target.value)}
            />
        </div>
        <Button style={{ width: "100%" }} variant={variant.Primary} name={"Submit"} onClick={() => props.onSubmit()} />
    </Form>
)